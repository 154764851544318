var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"15px"}},[(_vm.action.type.startsWith('pool:'))?_c('div',[(_vm.action.type === 'pool:recover_stake')?_c('div',[_vm._m(0)],1):(_vm.action.type === 'pool:recover_stake_ok' || _vm.action.type === 'pool:new_stake_ok')?_c('div',[_vm._m(1)],1):(_vm.action.type === 'pool:new_stake')?_c('div',[(_vm.amount > 5000000000)?_c('div',[_vm._m(2)],1):_c('div',[_vm._m(3)],1)]):(_vm.action.type === 'pool:update_validator_set_hash' || _vm.action.type === 'pool:process_withdraw_requests')?_c('div',[_vm._m(4)],1):_vm._e()]):(_vm.action.type === 'jetton:burn' || _vm.action.type === 'jetton:burn_notification')?_vm._m(5):(_vm.action.type === 'jetton:internal_transfer')?_vm._m(6):(_vm.action.type === 'nft:ownership_assigned' || _vm.action.type === 'jetton:transfer_notification')?_vm._m(7):(_vm.action.type === 'nft:transfer' || _vm.action.type === 'jetton:transfer')?_vm._m(8):(_vm.action.type.endsWith(':excesses'))?_vm._m(9):_vm._m(10)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('icon-unstake',{attrs:{"fill":"currentColor"}})
},function (){var _vm=this,_c=_vm._self._c;return _c('icon-confirmation',{attrs:{"fill":"currentColor"}})
},function (){var _vm=this,_c=_vm._self._c;return _c('icon-stake',{attrs:{"fill":"currentColor"}})
},function (){var _vm=this,_c=_vm._self._c;return _c('icon-service-action',{attrs:{"fill":"currentColor"}})
},function (){var _vm=this,_c=_vm._self._c;return _c('icon-service-action',{attrs:{"fill":"currentColor"}})
},function (){var _vm=this,_c=_vm._self._c;return _c('icon-burn',{attrs:{"fill":"currentColor"}})
},function (){var _vm=this,_c=_vm._self._c;return _c('icon-internal-transfer',{attrs:{"fill":"currentColor"}})
},function (){var _vm=this,_c=_vm._self._c;return _c('icon-in',{attrs:{"fill":"currentColor"}})
},function (){var _vm=this,_c=_vm._self._c;return _c('icon-out',{attrs:{"fill":"currentColor"}})
},function (){var _vm=this,_c=_vm._self._c;return _c('icon-excesses',{attrs:{"fill":"currentColor"}})
},function (){var _vm=this,_c=_vm._self._c;return _c('icon-tx-chain',{attrs:{"fill":"none"}})
}]

export { render, staticRenderFns }