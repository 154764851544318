var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tbody',[_c('tr',[_c('td',{staticStyle:{"width":"47px"}},[_c('router-link',{staticClass:"tx-table-cell-icon",class:{ 'tx-table-cell-icon--error': !_vm.is_success || _vm.is_bounced },attrs:{"to":{ name: 'tx', params: _vm.txLinkRouteParams },"title":_vm.is_success ? undefined : _vm.$t('tx.error_icon_tooltip', [_vm.exitCode])}},[(_vm.is_bounced)?_vm._m(0):(_vm.action)?_c('action-icon-mobile',{attrs:{"action":_vm.action,"amount":_vm.amount}}):[(_vm.is_success)?[(_vm.is_out)?_vm._m(1):_vm._m(2)]:_vm._m(3)]],2)],1),_vm._v(" "),_c('td',{staticStyle:{"max-width":"120px"}},[_c('ui-timeago',{staticClass:"muted",staticStyle:{"font-size":"12px","margin-left":"7px"},attrs:{"timestamp":_vm.timestamp}}),_vm._v(" "),_c('div',{staticStyle:{"margin-left":"7px"}},[(_vm.is_external)?_c('span',{staticClass:"muted",domProps:{"textContent":_vm._s(_vm.$t('address.tx_table.external'))}}):_vm._e(),_vm._v(" "),(_vm.is_aggregated)?_c('router-link',{attrs:{"to":{ name: 'tx', params: _vm.txLinkRouteParams }},domProps:{"textContent":_vm._s(_vm.$tc('address.tx_table.address_count', _vm.outputCount))}}):(_vm.is_log)?[_vm._v("\n                    "+_vm._s(_vm.$t('address.tx_table.nowhere'))+"\n                ")]:(!_vm.is_out)?_c('ui-address',{attrs:{"address":_vm.sender,"type":_vm.source_type}}):_c('ui-address',{attrs:{"address":_vm.receiver,"type":_vm.destination_type}})],2)],1),_vm._v(" "),_c('td',[(_vm.action)?_c('action-badge-mobile',{attrs:{"action":_vm.action,"amount":_vm.amount,"meta":_vm.meta,"from":_vm.from,"to":_vm.to,"is_out":_vm.is_out}}):(_vm.is_service || _vm.is_external || _vm.is_log)?[_c('div',{staticClass:"tx-table__cell tx-table__cell--align-right muted"},[_vm._v("\n                    N/A\n                ")])]:[_c('div',{staticClass:"tx-table__cell tx-table__cell--align-right",staticStyle:{"padding-left":"0"}},[_c('div',{class:{
                        'tx-amount--in': !_vm.is_out,
                        'tx-amount--out': _vm.is_out,
                    }},[_vm._v("\n                        "+_vm._s(_vm.$ton(_vm.amount))+" TON\n                    ")])]),_vm._v(" "),_c('div',{staticClass:"tx-table__cell tx-table__cell--align-right muted",staticStyle:{"padding-left":"0"}},[_vm._v("\n                    ≈ "),_c('ui-fiat',{attrs:{"tonValue":_vm.amount}})],1)]],2)]),_vm._v(" "),(_vm.op == 0x2167da4b)?_c('tr',[_c('td',{staticStyle:{"border-top":"none"}}),_vm._v(" "),_c('td',{staticStyle:{"border-top":"none"},attrs:{"colspan":"2"}},[_c('div',{staticClass:"tx-table__cell",staticStyle:{"padding-left":"7px"}},[_c('div',{staticClass:"tx-msg-block"},[_c('span',{staticClass:"muted",domProps:{"textContent":_vm._s(_vm.$t('tx.encrypted_message'))}})])])])]):_vm._e(),_vm._v(" "),(_vm.message)?_c('tr',[_c('td',{staticStyle:{"border-top":"none"}}),_vm._v(" "),_c('td',{staticStyle:{"border-top":"none"},attrs:{"colspan":"2"}},[_c('div',{staticClass:"tx-table__cell",staticStyle:{"padding-left":"7px"}},[_c('div',{staticClass:"tx-msg-block"},[_vm._v("\n                    "+_vm._s(_vm.message)+"\n                ")])])])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('icon-tx-bounced',{attrs:{"fill":"currentColor"}})
},function (){var _vm=this,_c=_vm._self._c;return _c('icon-out',{attrs:{"fill":"none"}})
},function (){var _vm=this,_c=_vm._self._c;return _c('icon-in',{attrs:{"fill":"none"}})
},function (){var _vm=this,_c=_vm._self._c;return _c('icon-tx-error',{attrs:{"fill":"currentColor"}})
}]

export { render, staticRenderFns }