var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',{staticClass:"tx-history-wrap desktop-table"},[_c('table',{staticClass:"last-blocks-mini-table"},[_c('thead',[_c('tr',[_c('th',{domProps:{"textContent":_vm._s(_vm.$t('blocks.block'))}}),_vm._v(" "),_c('th',{domProps:{"textContent":_vm._s(_vm.$t('blocks.transactions'))}}),_vm._v(" "),_c('th',{domProps:{"textContent":_vm._s(_vm.$t('common.time'))}})])]),_vm._v(" "),(_vm.blocks.length === 0)?_c('tbody',_vm._l((15),function(i){return _c('tr',[_vm._m(0,true),_vm._v(" "),_vm._m(1,true),_vm._v(" "),_vm._m(2,true)])}),0):_c('tbody',_vm._l((_vm.blocks),function(block){return _c('tr',{key:`${block.workchain}:${block.seqno}`,staticClass:"new-row-animation"},[_c('td',[_c('ui-link',{attrs:{"to":{
                            name: 'block',
                            params: { ...block },
                        }},domProps:{"textContent":_vm._s(block.seqno)}})],1),_vm._v(" "),_c('td',{domProps:{"textContent":_vm._s(block.tx_count)}}),_vm._v(" "),_c('td',{domProps:{"textContent":_vm._s(_vm.getTime(block.gen_utime))}})])}),0)])]),_vm._v(" "),(_vm.blocks.length > 0)?_c('div',{staticClass:"show-more-container"},[_c('ui-link',{attrs:{"to":{
            name: 'blocks',
            query: { wc: _vm.blocks[0].workchain },
        }}},[_c('button',{staticClass:"show-more-container__button"},[_vm._v("\n                "+_vm._s(_vm.$t('common.show_more'))+"\n                "),_c('svg',{pre:true,attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","style":"margin-left: 4px; width: 16px; height:12px; transform: scale(.8);"}},[_c('path',{pre:true,attrs:{"stroke":"currentColor","stroke-width":"2.2","stroke-linecap":"round","stroke-linejoin":"round","d":"m1.5 4.75 5.5 5.5 5.5-5.5"}})])])])],1):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('span',{staticClass:"skeleton"},[_vm._v("123456789")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('span',{staticClass:"skeleton"},[_vm._v("3")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('span',{staticClass:"skeleton"},[_vm._v("21:17:22")])])
}]

export { render, staticRenderFns }